<template>
  <app-page :toolbar="toolbar" :list="list" />
</template>

<script>
export default {
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '60px',
        model: {
          date: undefined,
          proposeUserId: undefined
        },
        fields: [
          {
            label: '日期',
            prop: 'date',
            type: 'date',
            placeholder: '请输入要搜索的日期',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            pickerOptions: {
              firstDayOfWeek: 1
            }
          }
        ]
      },
      list: {
        url: '/opinion',
        method: 'post',
        fields: [
          { label: '提出人', prop: 'proposeUserName', width: 120 },
          { label: '提示内容', prop: 'content', type: 'raw' },
          { label: '提出日期', prop: 'createTime', width: 220 }
        ],
        action: false
      }
    };
  }
};
</script>

<style></style>
